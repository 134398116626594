/* The container <div> that has dropdown button and contents */
.service-dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.serivce-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 400px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
  
/* Links inside the dropdown */
.serivce-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.serivce-dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.service-dropdown:hover .serivce-dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .service-dropbtn button {background-color: #817c38;}