@font-face {
  font-family: Hoefler Text;
  src: url("../fonts/Hoefler-Text.ttc");
  font-family: SF Pro Display Regular;
  src: url("../fonts/SF-Pro-Display-Regular.otf");
}

.BookNowBtn {
  font-family: Hoefler Text;
  background-color: #d3a625;
  border: none;
  border-radius: 4px;
  width: 135px;
  height: auto;
  align-items: center;
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 24px;
  padding: 0px;
  margin: 5px;
  text-decoration: none;
}
@media screen and (min-width: 500px) and (max-width: 900px) {
  .BookNowBtn {
    font-family: Hoefler Text;
    background-color: #d3a625;
    border: none;
    border-radius: 4px;
    width: 100px;
    height: auto;
    align-items: center;
    text-decoration: none;
    color: white;
    display: flex;
    justify-content: center;
    font-size: 17px;
    padding: 0px;
    margin: 5px;
    text-decoration: none;
  }
}
