.carousel-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    background-color: #f0f0f0;
    overflow: hidden;
    position: relative;
  }
  
  .carousel-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-img img {
    max-height: 100%; 
    width: auto;
    object-fit: contain; 
  }