.ManageContainer {
    margin: auto;
    width: 80%;
    
}

.Menu {
    width: 100%;
}

.RowContainer {
    width: 100%;
    background-color: lightgrey;
    padding: 1%;
    min-height: 500px;
    border-radius: 5px;
    padding: 15px;
}

.ServiceRow {
    background-color: white;
    border-radius: 25px;
    padding: 20px;
    margin-bottom: 3px;
}
